import React from 'react'
import { Link } from 'gatsby'
import clsx from 'clsx'

const PostsPagination = ({ previousPage, nextPage, className = '' }) => (
  <ul className={clsx('posts-pagination container', className)}>
    {previousPage > 0 && (
      <li className="posts-pagination__link posts-pagination__link--previous">
        <Link to={`/blog/page/${previousPage}`}>Previous Page</Link>
      </li>
    )}

    {nextPage > 1 && (
      <li className="posts-pagination__link posts-pagination__link--next">
        <Link to={`/blog/page/${nextPage}`}>Next Page</Link>
      </li>
    )}
  </ul>
)

export default PostsPagination
